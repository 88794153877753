/* eslint-disable react/prop-types */
// literally just displays a couple pdf pages with no layout

/** @jsx jsx */
import { graphql } from "gatsby"
import { jsx } from "theme-ui"
import Metadata from "../components/Metadata"

const PdfDisplayTemplate = ({ data }) => {
  const d = data.markdownRemark.frontmatter
  return (
    <div sx={container}>
      <Metadata title={d.browserTitle} description={d.metaDescription} />
      <img sx={image} src={d.leftImage} alt="left" />
      <img sx={image} src={d.rightImage} alt="right" />
    </div>
  )
}

export default PdfDisplayTemplate

export const query = graphql`
  query ($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        browserTitle
        metaDescription
        leftImage
        rightImage
      }
    }
  }
`
const container = {
  display: "flex",
  flexDirection: ["column", "column", "column", "row"],
}
const image = {
  width: ["100%", "100%", "100%", "50%"],
}
